function updateBreadcrumb() {
    const { pathname, origin, search } = window.location;
    let paths = []; // {displayName: '首页', path: window.location.href}
    switch (pathname) {
        case "/":
            paths = [
                {
                    displayNameZH_CN: "首页",
                    displayNameZH_TW: "首頁",
                    displayNameEN_US: "HOME",
                    path: window.location.href,
                },
            ];
            break;
        case "/investorPage/investorHome":
            paths = [
                {
                    displayNameZH_CN: "投资者关系",
                    displayNameZH_TW: "投資者關係",
                    displayNameEN_US: "INVESTOR RELATIONS",
                    path: window.location.href,
                },
            ];
            break;
        case "/investorPage/announcementsOfficial":
            paths = [
                {
                    displayNameZH_CN: "投资者关系",
                    displayNameZH_TW: "投資者關係",
                    displayNameEN_US: "INVESTOR RELATIONS",
                    path: origin + "/investorPage/investorHome" + search,
                },
                {
                    displayNameZH_CN: "公告与通函",
                    displayNameZH_TW: "公告與通函",
                    displayNameEN_US: "Announcements and Circulars",
                    path: window.location.href,
                },
            ];
            break;
        case "/newsPage/newsCenter":
            paths = [
                {
                    displayNameZH_CN: "新闻中心",
                    displayNameZH_TW: "新聞中心",
                    displayNameEN_US: "MEDIA CENTRE",
                    path: window.location.href,
                },
            ];
            break;
        case "/allPage/sustainableDevelopment/index":
            paths = [
                {
                    displayNameZH_CN: "关于我们",
                    displayNameZH_TW: "關於我們",
                    displayNameEN_US: "About us",
                    path: origin + "/allPage/aboutUs" + search,
                },
                {
                    displayNameZH_CN: "可持续发展",
                    displayNameZH_TW: "可持續發展",
                    displayNameEN_US: "Sustainable development",
                    path: window.location.href,
                },
            ];
            break;
        case "/allPage/sustainableDevelopment/articleList":
            paths = [
                {
                    displayNameZH_CN: "关于我们",
                    displayNameZH_TW: "關於我們",
                    displayNameEN_US: "About us",
                    path: origin + "/allPage/aboutUs" + search,
                },
                {
                    displayNameZH_CN: "可持续发展",
                    displayNameZH_TW: "可持續發展",
                    displayNameEN_US: "Sustainable development",
                    path:
                        origin +
                        "/allPage/sustainableDevelopment/index" +
                        search,
                },
                {
                    displayNameZH_CN: "议题",
                    displayNameZH_TW: "議題",
                    displayNameEN_US: "Issue",
                    path: window.location.href,
                },
            ];
            break;
    }
    if (pathname.includes("/allPage/sustainableDevelopment/article/")) return;
    if (pathname === "/allPage/sustainableDevelopment/articleList") {
        window.localStorage.setItem(
            "currentBreadcrumbSUS",
            JSON.stringify(paths),
        );
    } else {
        window.localStorage.setItem("currentBreadcrumb", JSON.stringify(paths));
        window.localStorage.removeItem("currentBreadcrumbSUS");
    }
}

function initALabel() {
    const arrs = Array.from(document.querySelectorAll("a")).filter(
        i =>
            i.href &&
            i.href.includes("/allPage/sustainableDevelopment/article"),
    );
    arrs.forEach(i => {
        i.addEventListener("click", () => {
            updateBreadcrumb();
        });
    });
}

function getParameterByName(name, url) {
    let myUrl = url;
    if (!url) myUrl = window.location.href;
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(myUrl);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function changePathLang(path, lang) {
    const [str, search] = path.split("?");
    if (search) {
        const [searchStr, hash] = search.split("#");
        const arrs = searchStr.split("&").map(i => {
            if (i.startsWith("lang=")) {
                return `lang=${lang}`;
            }
            return i;
        });
        return str + "?" + arrs.join("&") + (hash ? `#${hash}` : "");
    }
    return str;
}

function initBreadcrumbDom() {
    const dom = document.querySelector(".app-breadcrumb");
    const currentBreadcrumbSUS = window.localStorage.getItem(
        "currentBreadcrumbSUS",
    );
    const currentBreadcrumb = window.localStorage.getItem("currentBreadcrumb");
    const finalPaths =
        window.location.pathname ===
        "/allPage/sustainableDevelopment/articleList"
            ? currentBreadcrumb
            : currentBreadcrumbSUS || currentBreadcrumb;
    if (dom && finalPaths) {
        const arrs = JSON.parse(finalPaths);
        const current = dom.querySelector(".current");
        const lang = getParameterByName("lang") || "ZH_CN";
        arrs.forEach(i => {
            // <a class="link" th:href="'/allPage/aboutUs?lang='+${lang}">[[#{aboutUs.aboutUsTitle}]]</a>
            // <img class="arrow" th:src="@{/assets2/icons/page_arrow_right@2x.png}" />
            const a = document.createElement("a");
            a.classList.add("link");
            a.href = changePathLang(i.path, lang);
            a.innerText = i[`displayName${lang}`];
            dom.insertBefore(a, current);
            const img = document.createElement("img");
            img.classList.add("arrow");
            img.src = "/assets2/icons/page_arrow_right@2x.png";
            dom.insertBefore(img, current);
        });
    }
}

initALabel();

initBreadcrumbDom();

updateBreadcrumb();
